<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container-fluid">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <Avatar size="large" class="primary" icon="pi pi-user" />
                                    </div>
                                        <div class="ml-2">
                                            <div class="text-2xl text-primary font-bold">User Account Details</div>
                                            <div class="text-sm text-500"> Account details of user </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <hr />
            </template>
            <section class="page-section " >
                <div class="container-fluid">
                    <div class="grid ">
                        <div class="col-12 md:col-7 comp-grid" >
                            <div class="">
                                <div >
                                    <div class="relative-position" style="min-height:100px">
                                        <template v-if="!loading && item">
                                            <div class="row q-col-gutter-x-md">
                                                <div class="col-12">
                                                    <div class="grid align-items-center">
                                                        <div class="col">
                                                            Name: 
                                                        </div>
                                                        <div class="col font-bold">{{ item.name }}</div>
                                                    </div>
                                                    <hr />
                                                    <div class="grid align-items-center">
                                                        <div class="col">
                                                            Username: 
                                                        </div>
                                                        <div class="col font-bold">{{ item.username }}</div>
                                                    </div>
                                                    <hr />
                                                    <div class="grid align-items-center">
                                                        <div class="col">
                                                            Email: 
                                                        </div>
                                                        <div class="col font-bold">{{ item.email }}</div>
                                                    </div>
                                                    <hr />
                                                    <div class="grid align-items-center">
                                                        <div class="col">
                                                            Account Status: 
                                                        </div>
                                                        <div class="col font-bold">{{ item.account_status }}</div>
                                                    </div>
                                                    <hr />
                                                    <div class="grid align-items-center">
                                                        <div class="col">
                                                            Platform: 
                                                        </div>
                                                        <div class="col font-bold">{{ $utils.upper(item.platform )}}</div>
                                                    </div>
                                                    <hr />
                                                    <div class="grid align-items-center">
                                                        <div class="col">
                                                            Account Type: 
                                                        </div>
                                                        <div class="col font-bold">{{ item.user_type }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="loading">
                                            <div class="p-3 text-center">
                                                <ProgressSpinner style="width:50px;height:50px" />
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>
<script>
		//[--PAGE-IMPORT-STATEMENT--]
	import { defineAsyncComponent, ref } from "vue";
	import { PageMixin } from "../../mixins/page.js";
	import { ViewPageMixin } from "../../mixins/viewpage.js";
	import { mapActions, mapGetters, mapState } from "vuex";
	export default {
		name: 'viewAdministratorsPage',
		components: {
			//[--PAGE-COMPONENT-NAME--]
		},
		mixins: [PageMixin, ViewPageMixin ],
		props: {
			pageName: {
				type : String,
				default : 'administrators',
			},
			idName: {
				type: String,
				default: 'administrator_id',
			},
			routeName : {
				type : String,
				default : 'administratorsview',
			},
			pagePath: {
				type : String,
				default : 'administrators/view',
			},
			apiPath: {
				type : String,
				default : 'administrators/view',
			},
		},
		data() {
            return {
				item: {
					default :{
					},
				},
			}
		},
		computed: {
			pageTitle:{
				get: function () {
					return "Administrator Details"
				}
			},
			currentRecord: {
				get: function () {
					return this.$store.getters["administrators/currentRecord"];
				},
				set: function (value) {
					this.$store.commit("administrators/setCurrentRecord", value);
				},
			},
		},
		meta() {
			return {
				title: this.pageTitle
			}
		},
		methods: {
			...mapActions("administrators", [ "fetchRecord", "deleteRecord"]),
				getActionMenuModel(data){
					return []
				},
		},
		watch: {
			$route (to, from){
				//only fetch data when navigated to this page
				if(to.name == this.routeName){
					//this.load();
				}
			},
		},
		async mounted() {
		},
		async created() {
		},
	};
</script>
<style scoped>
</style>
